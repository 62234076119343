/**
 * Loyalty scheme - Cart - Points instance
 * Web Components configuration for the loyalty scheme points instance in the cart
 * cart/details
 *
 * Components:
 * + Points instance
 */

// Page URL
const pageURL = window.location.href

// Cart details page
if (pageURL.includes('cart/details')) {
  // If - Cart component
  if (document.querySelector('.tn-cart-component')) {
    // If not empty cart message
    if (!document.querySelector('.tn-empty-cart-message')) {
      // Session Detail
      const session = window.dataLayer.find(function (e) { return e.hasOwnProperty('session') }).session
      const encryptedSessionKey = btoa(session.key)

      // Products
      const products = window.dataLayer.find(function (e) { return e.hasOwnProperty('transactionId') }).transactionProducts

      // Create Element
      const cartPointsInstance = document.createElement('twc-loyalty-scheme-points-instance')

      // Add dataset
      cartPointsInstance.dataset.tnew = encryptedSessionKey

      // Loop instances
      const cartInstances = document.getElementsByClassName('tn-cart__section-items')
      for (const instance of [...cartInstances]) {
        // Find item element
        const instanceItem = instance.querySelector('.tn-cart-item')

        // Find summary element
        const instanceItemSummary = instance.querySelector('.tn-cart-item-summary')

        // Add loyalty points list item
        const loyaltyPointsListItem = document.createElement('li')
        loyaltyPointsListItem.setAttribute('class', 'tn-cart-item-summary__property tn-cart-item-summary__property--primary tn-cart-item-summary__property--loyalty-points')

        // Add data price
        const instanceId = { ...instanceItem.dataset }.tnPerformance
        if (instanceId) {
          cartPointsInstance.dataset.price = products.find(product => product.sku.includes(instanceId.toString())).price

          // Append component to list item
          loyaltyPointsListItem.append(cartPointsInstance)

          // Append list item to summary
          instanceItemSummary.append(loyaltyPointsListItem)
        }
      }
    }
  }
}
