/**
 * Loyalty scheme - Cart - Points receipt
 * Web Components configuration for the loyalty scheme points receipt in the cart
 * cart/receipt
 *
 * Components:
 * + Points receipt
 */

// Page URL
const pageURL = window.location.href

// Cart receipt page
if (pageURL.includes('cart/receipt')) {
  // Session Detail
  const session = window.dataLayer.find(function (e) { return e.hasOwnProperty('session') }).session
  const encryptedSessionKey = btoa(session.key)

  // Create Element
  const cartPointsReceipt = document.createElement('twc-loyalty-scheme-points-receipt')

  // Add dataset
  cartPointsReceipt.dataset.tnew = encryptedSessionKey
  cartPointsReceipt.dataset.orderId = location.pathname.split('/')[3]
  cartPointsReceipt.dataset.total = 0

  // Append component
  const cartSubTotal = document.querySelector('.tn-cart-totals__line-item--subtotal')
  const loyaltyPointsLineItem = document.createElement('div')
  loyaltyPointsLineItem.setAttribute('class', 'tn-cart-totals__line-item tn-cart-totals__line-item--loyalty')

  // Append component
  loyaltyPointsLineItem.append(cartPointsReceipt)

  // Add line item after subtotal
  cartSubTotal.after(loyaltyPointsLineItem)
}
