/**
 * Loyalty scheme - Cart - Points cart
 * Web Components configuration for the loyalty scheme points cart in the cart
 * cart/details
 *
 * Components:
 * + Points cart
 */

// Page URL
const pageURL = window.location.href

// Cart details page
if (pageURL.includes('cart/details')) {
  // If - Cart component
  if (document.querySelector('.tn-cart-component')) {
    // If not empty cart message
    if (!document.querySelector('.tn-empty-cart-message')) {
      // Session Detail
      const session = window.dataLayer.find(function (e) { return e.hasOwnProperty('session') }).session
      const encryptedSessionKey = btoa(session.key)

      // Products
      const products = window.dataLayer.find(function (e) { return e.hasOwnProperty('transactionId') }).transactionProducts

      // Create Element
      const cartPointsCart = document.createElement('twc-loyalty-scheme-points-cart')

      // Valid products
      const invalidProductCategories = ['Contribution']
      const validProducts = products.filter(product => !invalidProductCategories.includes(product.category))

      // Add dataset
      cartPointsCart.dataset.tnew = encryptedSessionKey
      cartPointsCart.dataset.total = validProducts.reduce((a, b) => a + b.price, 0)

      // Append component
      const cartSubTotal = document.querySelector('.tn-cart-totals__line-item--subtotal')
      const loyaltyPointsLineItem = document.createElement('div')
      loyaltyPointsLineItem.setAttribute('class', 'tn-cart-totals__line-item tn-cart-totals__line-item--loyalty')

      // Append component
      loyaltyPointsLineItem.append(cartPointsCart)

      // Add line item after subtotal
      cartSubTotal.after(loyaltyPointsLineItem)
    }
  }
}
